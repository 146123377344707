
(function($) {

	var _id_count = 0;

	$.MessageBox = function(options) {
		var id = 'cf-messagebox' + (++_id_count);
		var $el = $("<div>", {'class':'modal fade' + (options['class'] ? ' ' + options['class'] : ''), 'id':id})
		;

		if (options.header) {
			$el.append($("<div>", {'class':'modal-header'}).append(options.header))
		}
		else {
			$el.append($("<div>", {'class':'modal-header'}).append($("<h3>", {'text':options.title})))
		}
		if (options.message) {
			$el.append($("<div>", {'class':'modal-body'}).append($("<p>", {'text':options.message})));
		}
		else if (options.body) {
			$el.append($("<div>", {'class':'modal-body'}).append($(options.body)));
		}

		var $footer = $("<div>", {'class':'modal-footer'}).appendTo($el);

		var event_handlers = {};
		if (options.buttons) $.each(options.buttons, function(i, btn) {
			var id = 'btn-' + (++_id_count);
			if (btn === 'cancel') {
				$footer.append($("<button>", {'class':'btn btn--cancel btn--small', 'id':id, 'data-dismiss':'modal', 'text':'Cancel'}));
			}
			else if (typeof btn === 'string') {
				$footer.append($(btn));
			}
			else {
				var attribs = {'class':'btn' + (btn['class'] ? ' ' + btn['class'] : ''), 'id':id, 'text':btn['label']};
				if (btn['loading-text']) attribs['data-loading-text'] = btn['loading-text'];
				if (!btn['click']) attribs['data-dismiss'] = 'modal';
				$footer.append($("<button>", attribs));
				if (btn['click']) event_handlers[id] = btn['click'];
			}
		});

		$(document.body).append($el);

		$('#' + id + ' button').click(function(e) {
			var $b = $(e.target);
			if ($b.attr('data-loading-text')) {
				$b.button('loading');
			}
			var id = $b.attr('id');
			if (event_handlers[id]) {
				setTimeout(function() {
					event_handlers[id].call(e.target, e);
				}, 100);
			}
			else {
				setTimeout(function() {
					$el.modal('hide');
				}, 100);
			}
		});

		$el.on('show', function() {
			$el.find('h3').first().prop('tabIndex', 0).focus();
		});

		return $('#' + id);
	};


})(jQuery);


