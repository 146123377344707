
(function(G) {

	G.ccore = G.ccore || {};

	ccore.gaq = function(params) {
		if (typeof _gaq !== 'undefined') {
			_gaq.push(params);
		}
		if (typeof console !== 'undefined' && console.log) {
			var page = document.getElementById('page');
			if ((page && /is-dev/.test(page.className)) || /env-(dev|local|stage)/.test(document.documentElement.className)) {
				console.log('GA: ', params);
			}
		}
	};

})(window);

