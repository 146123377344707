
(function($) {

	var VERSION = "1.1.0";

	$.cf = $.cf || {};

	/**
	 * Installer object.
	 * Maintains a list of callbacks to be applied
	 * to jQuery objects, generally for setting up
	 * plugins on DOM trees.
	 *
	 * @param string name Group name
	 */
	var Installers = function(name) {
		this.name = name;
		this.callbacks = [];
	};

	/**
	 * Register an installer function.
	 * Optional selector argument makes the callback apply
	 * to descendant elements that match it.
	 * @param string selector CSS selector (optional)
	 * @param function callback
	 */
	Installers.prototype.register = function() {
		var installfunc;
		if (arguments.length === 1) {
			installfunc = arguments[0];
		}
		else {
			var sel = arguments[0], func = arguments[1];
			installfunc = function($root) {
				var $els = $root.find(sel);
				$els.each(function() {
					return ($.proxy(func, this))($(this));
				});
			};
		}

		this.callbacks.push(installfunc);

		return installfunc;
	};

	/**
	 * Run callbacks on given elements.
	 * @param jQuery elements jQuery object to call the callbacks with.
	 */
	Installers.prototype.install = function($elements) {
		var callbacks = this.callbacks;
		$elements.each(function() {
			var el = this
				, $el = $(this)
			;
			$.each(callbacks, function(i, func) {
				($.proxy(func, el))($el);
			});
		});
		$elements.trigger('cfinstalled');
	};

	// cache of named Installers objects
	var installers = {};

	/**
	 * Get a named installer group object, or the
	 * default group if no name specified.
	 * @param  string optional_group_name (optional)
	 * @return Installers
	 */
	$.cf.installers = function(optional_group_name) {
		var group_name = optional_group_name || '__default__';
		if (typeof installers[group_name] === 'undefined') {
			installers[group_name] = new Installers(group_name);
		}
		return installers[group_name];
	};

	/**
	 * Register an install function in the default group, also
	 * set it up to run when DOM is ready.
	 * @param  string selector (optional)
	 * @param  function installer callback
	 */
	$.cf.installer = function(/*(func | selector, func)*/) {
		var installer = $.cf.installers();
		var installfunc = installer.register.apply(installer, arguments);

		$(document).ready(function() {
			installfunc($(document.body));
		});
	};

	$.cf.installer.version = VERSION;

	/**
	 * Run installers on selected jquery objects.
	 * optional_group_name specifies which installer group
	 * to run.
	 */
	$.fn.cf_install = function(optional_name) {
		$.cf.installers(optional_name).install(this);
	};

})(jQuery);
