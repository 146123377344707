(function($) {

	$.cfui = $.cfui || {};

	var scrollBarWidth = null;

	function stopEvent(e) {
		e = e ? e : window.event;
		if (e.stopPropagation) e.stopPropagation();
		if (e.preventDefault) e.preventDefault();
		e.cancelBubble = true;
		e.cancel = true;
		e.returnValue = false;
		return false;
	}

	$.fn.extend({
		// param: (boolean) onlyWhenScrollbarVisible
		// If set to true, target container will not intercept mouse wheel
		//     event if it doesn't have its own scrollbar
		scrollStop: function(onlyWhenScrollbarVisible) {
			return this.each(function(){
				$(this).on('mousewheel DOMMouseScroll', function(e) {
					if (onlyWhenScrollbarVisible && this.scrollHeight <= this.offsetHeight)
						return;

					e = e.originalEvent;
					var delta = (e.wheelDelta) ? -e.wheelDelta : e.detail;
					var isIE = Math.abs(delta) >= 120;
					var scrollPending = isIE ? delta / 2 : 0;
					if (delta < 0 && (this.scrollTop + scrollPending) <= 0) {
						this.scrollTop = 0;
						stopEvent(e);
					}
					else if (delta > 0 && (this.scrollTop + scrollPending >= (this.scrollHeight - this.offsetHeight))) {
						this.scrollTop = this.scrollHeight - this.offsetHeight;
						stopEvent(e);
					}
				});
			});
		}
	});

	$.fn.ensureInView = function() {
		return this.each(function() {
			var $this = $(this)
				, $container = $this
			;
			while (true) {
				$container = $container.parent();
				if ($container.length) {
					var overflow = $container.css('overflow-y');
					if (overflow === 'scroll' || overflow === 'auto') {
						break;
					}
				}
				else {
					$container = $(window);
					break;
				}
			}

			var offset = $this.offset()
				, bottom = offset.top + $this.height()
			;
			if (bottom > $container.height()) {
				$container.animate({
					scrollTop: $container.scrollTop() + (bottom - $container.height())
				});
			}
		});
	};

	$.cfui.getScrollBarWidth = function() {
		if (scrollBarWidth === null) {
			var $div = $("<div>").css({
				width: '100px',
				height: '100px',
				overflow: 'scroll',
				position: 'absolute',
				top: '-9999px'
			})
			.appendTo($(document.body))
			;
			var div = $div.get(0);
			scrollBarWidth = div.offsetWidth - div.clientWidth;
			$div.remove();
		}
		return scrollBarWidth;
	};

	$.fn.animateAuto = function(options){
		options = $.extend({
			property: 'both',
			speed: 300,
			center: true,
			max_width: $(window).width() - 100,
			max_height: $(window).height() - 100
		}, options || {});
		var elem, height, width;
		return this.each(function(i, el){
			el = $(el), elem = el.clone().css({"height":"auto","width":"auto"}).appendTo("body");
			width = parseInt(elem.css("width"), 10);
			width = Math.min(width, options.max_width);
			height = parseInt(elem.css("height"), 10);
			if (height > options.max_height) {
				width += $.cfui.getScrollBarWidth();
				height = options.max_height;
			}
			elem.remove();

			var css = {};
			if (options.property === 'width' || options.property === 'both') {
				css.width = width;
				if (options.center) {
					css.left = Math.max(20, (options.max_width - width) / 2);
				}
			}
			if (options.property === 'height' || options.property === 'both') {
				css.height = height;
				if (options.center) {
					css.top = Math.max(20, (options.max_height - height) / 2);
				}
			}

			if (options.before) {
				options.before(css);
			}

			el.animate(css, options.speed, options.completed);
		})
	}


})(window.jQuery);

