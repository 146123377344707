
(function($, undefined) {

	$.cf = $.cf || {};

	$.cf.access = (function() {

		var _checks = {}
			, _rules = {}
			, _actions = {}
			, _last_failure = null
		;

		function check(rule, args) {
			if (typeof rule === 'string') {
				rule = rule.split('||');
				for (var i = 0; i < rule.length; ++i) {
					var func = _checks[rule[i]];
					if (!func) throw new Error('Unknown access check "' + rule[i] + '"');
					if (func.apply(window, args)) {
						return true;
					}
				}
				_last_failure = {
					'rules': rule,
					'args': args
				};
			}
			else if (typeof rule === 'function') {
				return rule.apply(window, args);
			}
			return false;
		}

		function rules() {
			if (typeof _rules === 'function') {
				_rules = _rules();
			}
			return _rules;
		}

		return {
			validator: function(name, checker) {
				if (typeof checker === undefined) return _checks[name];
				_checks[name] = checker;
				return this;
			},

			action: function(name, runner) {
				if (typeof runner === undefined) return _actions[name];
				_actions[name] = runner;
				return this;
			},

			rules: function(rules) {
				if (typeof rules === undefined) return rules();
				_rules = rules;
				return this;
			},

			rule: function(name, validator) {
				if (typeof validator === undefined) return rules()[name];
				rules()[name] = validator;
				return this;
			},

			can: function(rule_name/*, args*/) {
				var lrules = rules()
					, rule = lrules ? lrules[rule_name] : undefined
					, type = typeof rule
					, args = [].slice.call(arguments, 1)
				;
				_last_failure = null;
				if (type === 'undefined') {
					var m = rule_name.match(/^[^\.]+\.(.+)$/);
					if (m) {
						return $.cf.access.can.apply(window, [m[1]].concat(args));
					}
				}
				if (type === 'boolean') {
					return rule;
				}
				if (type !== 'object') {
					rule = [rule];
				}
				for (var i = 0; i < rule.length; ++i) {
					if (!check(rule[i], args)) {
						if (!_last_failure) {
							_last_failure = {};
							if (typeof console !== 'undefined') {
								console.log(arguments, rules());
							}
						}
						_last_failure.action = rule_name;
						return false;
					}
				}
				return (rule.length > 0);
			},

			resolve: function() {
				if (_last_failure) {
					var action = _actions[_last_failure.action];
					if (action) return action.apply(window, arguments);
					if (_last_failure.rules) {
						for (var i = 0; i < _last_failure.rules.length; ++i) {
							action = _actions[_last_failure.rules[i]];
							if (action) return action.apply(window, arguments);
						}
					}

					if ((action = _actions[''])) {
						action.apply(window, arguments);
					}
				}
			},

			run_action: function(name) {
				var action = _actions[name];
				if (action) {
					return action.apply(window, [].slice.call(arguments, 1));
				}
			}

		};
	})();

})(jQuery);

