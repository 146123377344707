(function($) {

	var _id_count = 0;

	$.cfui = $.cfui || {};

	var Popover = function(options) {
		options = options || {};

		var self = this;
		this.id = 'cf-popover' + (++_id_count);
		var dialog_class = 'modal fade cf-popover' + (options['class'] ? ' ' + options['class'] : '');
		var $close_button = $("<button>", {'class': 'btn-close-dialog', 'text': 'Close'});
		this.$el = $("<div>", {'class':dialog_class, 'id':this.id})
		.append($("<div>", {'class':'modal-header'}).append($("<h3>", {'text':options.title || ''})).append($close_button))
		;

		$close_button.on('click', function() {
			if (self.$close_action && self.$close_action.length) {
				self.$close_action.click();
			}
			else {
				self.hide();
			}
		});

		this.$body = $("<div>", {'class':'modal-body'}).appendTo(this.$el);

		if (options.body) {
			this.body(options.body);
		}

		this.$footer = $("<div>", {'class':'modal-footer'}).appendTo(this.$el);
		this.$footer.hide();

		this.$el.on({
			'click': function(e) {
				self._onclick(e);
			}
		});
		this.$body.on({
			'cfinstalled cms-editor-subcontent-add': function(e) {
				setTimeout(function() {
					self.resize(300);
				}, 1);
			},
			'cfinstalled cms-editor-subcontent-remove': function(e) {
				setTimeout(function() {
					self.resize(300);
				}, 1);
			}
		});

		this.$el.on('hidden', function(e) {
			if (self.$el.is(e.target)) $(this).remove();
		})

		this.$el.data('cf-popover', this);
	};

	Popover.prototype.title = function(v) {
		if (typeof v === 'undefined') return this.$el.find('.modal-header h3').text();
		this.$el.find('.modal-header h3').text(v);
		return this;
	};

	Popover.prototype.subtitle = function(v) {
		if (typeof v === 'undefined') return this.$el.find('.modal-header p.subtitle').html();
		var $subtitle = this.$el.find('.modal-header p.subtitle');
		if (0 === $subtitle.length) {
			$subtitle = $("<p>", {'class': 'subtitle'}).appendTo(this.$el.find('.modal-header'));
		}
		$subtitle.html(v);
		return this;
	};

	Popover.prototype.body = function(v) {
		if (typeof v === 'undefined') return this.$body.children();
		var children = this.$body.children();
		var had_children = children.length;
		var self = this;
		this.$body.children().remove();
		this.$body.append($(v));
		if ($.fn.cmsChangeTracker) {
			this.$body.find('form.cmsEditor').cmsChangeTracker();
		}
		this.$footer.children().remove();
		var $fm_footer = this.$body.find('.fm-footer');
		if ($fm_footer.length && !$fm_footer.parents('.cf-popover-keep-form-footer').size()) {
			this.$footer.show();
			this.$el.addClass('cf-popover-with-footer');
			$fm_footer.each(function() {
				var $this = $(this);
				$this.data('fm-form', $this.closest('form'));
			});
			this.$footer.append($fm_footer);
		}
		else {
			this.$footer.hide();
			this.$el.removeClass('cf-popover-with-footer');
		}
		this.$close_action = this.$el.find('.cf-popover-close-action');
		this.$body.cf_install();
		// this.resize(had_children ? 300 : 0);
		return this;
	};

	Popover.prototype.resize = function(speed) {
		var self = this
			, $xhr = this.$body.find('.xhr-content')
			, scrollTop = $xhr.scrollTop()
		;

		$xhr.css({
			'height': 'auto'
		})
		.scrollStop()
		.on('mousewheel DOMMouseScroll', function(e) {
			$('.ui-timepicker-wrapper').hide();
		})
		;

		self.$el.css({
			'margin': 0
		}).animateAuto({
			speed: speed,
			before: function(css) {
				var chrome_height = self.$el.find('.modal-header').outerHeight();
				if (self.$footer.is(':visible')) {
					chrome_height += self.$el.find('.modal-footer').outerHeight();
				}
				self.$el.css('overflow', 'hidden');
				var $body = self.$el.find('.modal-body');
				var ypad = $body.innerHeight() - $body.height();
				$xhr.css({
					'overflow-x': 'hidden',
					'overflow-y': 'auto',
					'height': (css.height - chrome_height - ypad) + 'px'
				})
				.scrollTop(scrollTop)
				;
			}
		});
	};

	Popover.prototype.reload = function() {
		this.load(this.url);
	};

	Popover.prototype.load = function(url) {
		var self = this;
		this.url = url;
		this._loading();
		var request = {
			type: 'GET',
			url: url,
			data: {iebust: (new Date).getTime()},
			success: function(response) {
				if (response.status === 'error') {
					self._error(request, response);
				}
				else {
					var url_path = url.replace(/^https?:\/\/[^\/]+/, '').replace(/iebust=[0-9]+/, '').replace(/\?$/, '');
					if (url_path.indexOf('?') === -1) url_path += '?virtualPageView=1';
					else url_path += '&virtualPageView=1';
					ccore.gaq(['_trackPageview', url_path]);
					self._loaded(response);
				}
			},
			error: function(response) {
				self._error(request, response);
			}
		};
		$.ajax(request);
		return this;
	};

	Popover.prototype.show = function() {
		var self = this;
		this.$el.modal()
		.on('shown', function() {
			if (self.$body.find('.spinner').length) {
				self.$body.spin();
				self.$body.find('.spinner').css('opacity', 0).animate({
					'opacity': 1
				});
			}
			self.resize(300);
		})
		;
		return this;
	};

	Popover.prototype.hide = function() {
		this.$el.modal('hide');
		return this;
	};

	// Renders a popover using HTML stored in a JSON string in a 'data-*' attribute value in the HTML document, rather than from Ajax.
	// The popover HTML is in the JSON object's 'body' property. The HTML from JSON is then passed to '_loaded()', and treated the same as Ajax content.
	// The two arguments are the selector string of the element containing the 'data-*' attribute, eg '#page', and the name of the data attribute itself,
	// not including the 'data-' prefix, so the attribute 'data-popover-contact' would be passed as the argument 'popover-contact'.
	// The JSON object can also specify subsequent content to render in the popover if the user clicks a specified element on the popover.
	/* Example within HTML document:
		<div id="my-container-selector"
		data-popover-content="'{
			"body": "<section><button id="next-popover">click here for next popover</button>popover content</section>",
			"nextPopoverLinkElement": "#next-popover",
			"nextPopoverContainerElement": "#my-container-selector",
			"nextPopoverDataAttribute": "data-second-popover",
		}'"
		data-second-popover="'{ "body": "<p>content of second popover<p>" }'"
		></div>
	*/
	Popover.prototype.useDocumentData = function(containerSelector, dataAttributeName) {

		var jsonObject = JSON.parse( $(containerSelector).attr('data-' + dataAttributeName)  );

		this._loading();
		this._loaded( {html: jsonObject.body} );
		this.show();
		this.linkForNextPopover(jsonObject);

	};

	// If clicking an element on the popover should lead to the popover's content being re-rendered, attach the deferred event handler to the specified
	// element in the current popover content.
	// On click, the popover will have its content replaced using the JSON string in the container element and its data attribute specified in the JSON object.
	Popover.prototype.linkForNextPopover = function(jsonObject) {
		if (jsonObject.nextPopoverLinkElement) {
			var popover = this;
			$('body').on('click', jsonObject.nextPopoverLinkElement, function() {
				popover.reRenderUsingDocumentData(jsonObject.nextPopoverContainerElement, jsonObject.nextPopoverDataAttribute);
			} );    
		}
	};

	// Re-renders the content of an existing popover, using HTML stored as a JSON string in a 'data-*' attribute, as with the 'useDocumentData()' method.
	// Tested as working with popovers initially rendered using document data, not tested with popovers that were created using Ajax content.
	// The arguments give the element and 'data-*' attribute that contain the new content for the popover. 
	Popover.prototype.reRenderUsingDocumentData = function(containerSelector, dataAttributeName) {
		var jsonObject = JSON.parse( $(containerSelector).attr('data-' + dataAttributeName)  );
		this._loaded( {html: jsonObject.body} );
		this.linkForNextPopover(jsonObject);
	};

	Popover.prototype._loading = function() {
		this.body($("<div>", {'class':'cf-popover-loading'}));
		this.$body.spin().find('.spinner').css({
			'left': 'auto',
			'margin': '0 auto'
		});
	};

	Popover.prototype._error = function(request, response) {
		var self = this;

		if (response.code === 'day-full') {
			this.body(
				$("<div>", {'class':'cf-popover-error'})
				.append($("<p>", {'text':'There are no more time slots available on this date.'}))
				.append(
					$("<div>", {'class':'fm-footer'})
					.append(
						$("<button>", {'class': 'btn btn-primary', 'text': 'OK'})
						.on('click', function(e) {self.hide()})
					)
				)
			);
			return;
		}

		this.body(
			$("<div>", {'class':'cf-popover-error'})
			.append($("<p>", {'text':'Sorry something went wrong, please try again.'}))
			.append(
				$("<div>", {'class':'fm-footer'})
				.append(
					$("<button>", {'class': 'btn btn-cancel', 'text': 'Cancel'})
					.on('click', function(e) {self.hide()})
				)
				.append(
					$("<button>", {'class': 'btn btn-primary', 'text': 'Retry'})
					.on('click', function(e) {
						e.preventDefault();
						self._loading();
						$.ajax(request);
						return false;
					})
				)
			)
		);
	};

	Popover.prototype._loaded = function(response) {
		if (response.status === 'done') {
			document.location.reload(true);
			this.hide();
		}
		this.$body.spin(false);
		this.$el.attr('class', this.$el.attr('class').replace(/ *cf-popover-dialog-[a-z_-]+/, ''));
		if (response.dialog_class) {
			this.$el.addClass('cf-popover-dialog-' + response.dialog_class);
		}
		this.body(response.html);
		this.subtitle('');
		var $h1 = this.$body.find('h1');
		if ($h1.length) {
			this.title($h1.text());
			$h1.remove();
			var $subtitle = this.$body.find('.subtitle').first();
			if ($subtitle.length) {
				this.subtitle($subtitle.html());
				$subtitle.remove();
			}
		}
		else if (response.title) {
			this.title(response.title);
		}
		else if (response.next) {
			if (response.status === 'done') document.location.href = response.next;
			else this.load(response.next);
		}
		$(this).trigger('cfuipopoverloaded');
	};

	Popover.prototype._posted = function(request, response) {
		switch (response.status) {
			case 'ok':
				if (response.next) this.load(response.next);
				else this._loaded(response);
			break;

			case 'saved':
				$(this).trigger('saved', [response]);
				if (response.next) this.load(response.next);
				else this.hide();
			break;

			case 'done':
				if (response.next) document.location.href = response.next;
				else document.location.reload(true);
			break;

			default:
				this._error(request, response);
			break;
		}
	};

	Popover.prototype._changeprompt = function(href) {
		var $form = this.$body.find('form.cmsEditor');
		if ($.fn.cmsChangeTracker && $form.length && $form.cmsChangeTracker('dirty')) {
			this.body(
				$("<div>", {'class': 'cf-popover-dialog cf-popover-messagebox'})
				.append($("<p>Do you want to <strong>save the changes</strong> you made to your <strong>" + this.title() + "</strong> before you go on?</p>"))
				.append($("<form>")
								.append($("<div>", {'class': 'fm-footer'})
												.append(
													$("<button>", {'class': 'btn', 'text': "No thanks, let's go"})
													.on('click', function(e) {
														e.preventDefault();
														document.location.href = href;
														return false;
													})
												)
												.append(
													$("<button>", {'class': 'btn btn-primary', 'text': "Yes, save my changes and go"})
													.on('click', function(e) {
														e.preventDefault();
														$form.cmsChangeTracker('save', function() {
															document.location.href = href;
														})
														return false;
													})
												)
											 )
							 )
			)
			.title('Save it before you go?')
			;
			return true;
		}
		return false;
	};

	Popover.prototype._onclick = function(e) {
		if (false === this.clickFormButton(e.target)) {
			e.preventDefault();
			return false;
		}
	};

	Popover.prototype.clickFormButton = function(button) {
		var $target = $(button);
		if ($target.closest('.cf-popover-nocapture').length) {
			$target = $target.closest('a');
			if ($target.length && !$target.attr('target') && this._changeprompt($target.attr('href'))) {
				return false;
			}
			return;
		}

		if ($target.is('span')) {
			$target = $target.closest('a, button');
		}

		if ($target.is('a')) {
			var href = $target.attr('href');
			if (!/^javascript:/.test(href) && !$target.closest('.redactor_editor, .redactor_box').length && !$target.attr('target')) {
				if (this.url) {
					var path = this.url.replace(/^https?:\/\/[^/]+/, '');
					if (href.substring(0, path.length) === path) {
						var m = href.match(/#([^#]+)$/);
						if (m) {
							// TODO: scroll to error
							return false;
						}
					}
				}
				this.load($target.attr('href'));
				return false;
			}
		}

		if ($target.is('[type="submit"]')) {
			if ($target.hasClass('btn-cancel')) {
				this.hide();
				return false;
			}
			else {
				var $form = $target.closest('.fm-footer').data('fm-form');
				if ( ! $form || $form.length === 0) {
					$form = $target.closest('form');
				}
				if ($form.hasClass('cf-noajax') || $form.hasClass('cf-popover-noajax')) {
					if ($form.hasClass('cf-popover-close')) {
						var validate = $form.data('cms-editor-validate');
						if (!validate || validate()) {
							this.hide();
						}
						else {
							return false;
						}
					}
					if (this.$el.hasClass('cf-popover-with-footer')) {
						$form.get(0).submit();
					}
					return;
				}
				if ($form.attr('cf-popover-submitted')) {
					return false;
				}
				$form.attr('cf-popover-submitted', '1');
				var data = $form.serialize();
				data += '&' + escape($target.attr('name')) + '=' + escape($target.attr('value'));
				$target.closest('.fm-footer').find('input').each(function() {
					if (this.type !== 'submit' && this.type !== 'button') {
						data += '&' + escape(this.name) + '=' + escape(this.value);
					}
				});
				this._loading();
				var self = this;
				var request = {
					type: $form.attr('method'),
					url: $form.attr('action'),
					data: data,
					success: function(response) {
						self._posted(request, response);
						$form.removeAttr('cf-popover-submitted');
					},
					error: function(response) {
						self._error(request, response);
						$form.removeAttr('cf-popover-submitted');
					}
				};
				$.ajax(request);
				return false;
			}
		}
	};

	$.cfui.Popover = Popover;

})(window.jQuery);

