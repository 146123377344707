// vim: et sw=2 ts=2

(function(G, $) {

  G.ccore = G.ccore || {};

  var Stats = function(url) {
    this.url = url;
  };

  Stats.prototype.action = function(stat, subject, data, sampleRate) {
    this.send({
      event: stat,
      subject: subject,
      data: data
    }, sampleRate);
  };

  Stats.prototype.increment = function(stat, sampleRate) {
    this.updateStats(stat, 1, sampleRate);
  };

  Stats.prototype.decrement = function(stat, sampleRate) {
    this.updateStats(stat, -1, sampleRate);
  };

  Stats.prototype.updateStats = function(stat, amount, sampleRate) {
    var data = {
      c: stat,
      v: amount
    };
    this.send(data, sampleRate);
  };

  Stats.prototype.send = function(data, sampleRate) {
    if (typeof sampleRate === 'undefined' || sampleRate >= 1 || Math.random() <= sampleRate) {
      $.ajax({
        url: this.url,
        type: 'post',
        data: data,
      });
    }
  };

  G.ccore.StatsLog = Stats;
  G.ccore.stats = new Stats('/api/stats/log');

})(window, window.jQuery);

