// vim: et sw=2 ts=2
(function(Modernizr, G) {

  G.cfui = G.cfui || {};

  // These breakpoint definitions are duplicated for scss in css/application/_settings.responsive.scss
  // If updating one, please also update the other.
  // TODO: Find a way to define these in a single place

  var breakpoints = {
    "palm-portrait-and-under":        "screen and (max-width: 320px)",

    "palm-landscape-only":            "screen and (min-width: 321px) and (max-width: 549px)",
    "palm-landscape-and-under":       "screen and (max-width: 549px)",
    "palm-landscape-and-up":          "screen and (min-width: 321px)",

    "standard-banner-ad-and-up":      "screen and (min-width: 729px)",

    "lap-only":                       "screen and (min-width: 550px) and (max-width: 800px)",
    "lap-and-under":                  "screen and (max-width: 800px)",
    "lap-and-up":                     "screen and (min-width: 550px)",

    "desk-and-up":                    "screen and (min-width: 801px)",
    "retina":                         "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx)"
  };

  G.cfui.mediaQueryBreakpoints = function() {return breakpoints};

  G.cfui.mediaQuery = function(name) {
    if (typeof breakpoints[name] === 'undefined') {
      console.error("Unknown breakpoint " + name);
      return Modernizr.mq(name);
    }
    return Modernizr.mq(breakpoints[name]);
  };

})(Modernizr, window);

